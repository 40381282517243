export default defineNuxtRouteMiddleware((to, _from) => {
    const user = useSupabaseUser();

    if (!user.value) {
        return navigateTo('/login');
    }
    // console.log(to)
    // return navigateTo(to)
    // const session = useSupabaseSession()
    // if (!session.value) {
    //     return navigateTo('/login')
    // }
})
